import logo from './logo.svg';
import './App.css';
import React, { useEffect, useState } from 'react';


function App() {
  return (
    <div style={{ margin: 15 }}>
      <h1 style={{ fontSize: 22, alignSelf: 'center',  }}>{'End-User License Agreement (EULA) of ClockinGames'}</h1>
      <p style={{ fontSize: 15, }}>This End-User License Agreement ("EULA") is a legal agreement between you and AllAmericanHighSchoolSport Inc.
        <br></br>
        This EULA agreement governs your acquisition and use of our ClockinGames software ("Software") directly from AllAmericanHighSchoolSport Inc or indirectly through a AllAmericanHighSchoolSport Inc authorized reseller or distributor (a "Reseller").
        <br></br>
        Please read this EULA agreement carefully before completing the installation process and using the ClockinGames software. It provides a license to use the ClockinGames software and contains warranty information and liability disclaimers.
        <br></br>
        If you register for a free trial of the ClockinGames software, this EULA agreement will also govern that trial. By clicking "accept" or installing and/or using the ClockinGames software, you are confirming your acceptance of the Software and agreeing to become bound by the terms of this EULA agreement.
        <br></br>
        If you are entering into this EULA agreement on behalf of a company or other legal entity, you represent that you have the authority to bind such entity and its affiliates to these terms and conditions. If you do not have such authority or if you do not agree with the terms and conditions of this EULA agreement, do not install or use the Software, and you must not accept this EULA agreement.
        <br></br>
        This EULA agreement shall apply only to the Software supplied by AllAmericanHighSchoolSport Inc herewith regardless of whether other software is referred to or described herein. The terms also apply to any AllAmericanHighSchoolSport Inc updates, supplements, Internet-based services, and support services for the Software, unless other terms accompany those items on delivery. If so, those terms apply.
  
        </p>
      <h4 style={{ fontSize: 17,   }}>License Grant</h4>
      <p style={{ fontSize: 15, }}>AllAmericanHighSchoolSport Inc hereby grants you a personal, non-transferable, non-exclusive licence to use the ClockinGames software on your devices in accordance with the terms of this EULA agreement.
        <br></br>
        You are permitted to load the ClockinGames software (for example a PC, laptop, mobile or tablet) under your control. You are responsible for ensuring your device meets the minimum requirements of the ClockinGames software.
        <br></br>
        You are not permitted to:
        <br></br>
        Edit, alter, modify, adapt, translate or otherwise change the whole or any part of the Software nor permit the whole or any part of the Software to be combined with or become incorporated in any other software, nor decompile, disassemble or reverse engineer the Software or attempt to do any such things
        Reproduce, copy, distribute, resell or otherwise use the Software for any commercial purpose
        Allow any third party to use the Software on behalf of or for the benefit of any third party
        Use the Software in any way which breaches any applicable local, national or international law
        use the Software for any purpose that AllAmericanHighSchoolSport Inc considers is a breach of this EULA agreement
      </p>
      <h1 style={{ fontSize: 17, paddingBottom: 5,  }}>Intellectual Property and Ownership</h1>
      <p style={{ fontSize: 15, }}>AllAmericanHighSchoolSport Inc shall at all times retain ownership of the Software as originally downloaded by you and all subsequent downloads of the Software by you. The Software (and the copyright, and other intellectual property rights of whatever nature in the Software, including any modifications made thereto) are and shall remain the property of AllAmericanHighSchoolSport Inc.
        <br></br>
        AllAmericanHighSchoolSport Inc reserves the right to grant licences to use the Software to third parties.
      </p>
      <h1 style={{ fontSize: 17, paddingBottom: 5,  }}>User Generated Contents</h1>
      <p style={{ fontSize: 15, }}>When you create or make available any Contents, you thereby represent and warrant that:
        <br></br>
        1. The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contents do not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any third party.
        <br></br>2. You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and to authorize us, the App, and other users of the App to use your Contents in any manner contemplated by the App and these Terms of Use.
        <br></br>3. You have the written consent, release, and/or permission of each and every identifiable individual person in your Contents to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of your Contents in any manner contemplated by the App and these Terms of Use.
        <br></br>4. Your Contents are not false, inaccurate, or misleading.
        <br></br>5. Your Contents are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.
        <br></br>6. Your Contents are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or otherwise objectionable (as determined by us).
        <br></br>7. Your Contents do not ridicule, mock, disparage, intimidate, or abuse anyone.
        <br></br>8. Your Contents do not advocate the violent overthrow of any government or incite, encourage, or threaten physical harm against another.
        <br></br>9. Your Contents do not violate any applicable law, regulation, or rule.
        <br></br>10. Your Contents do not violate the privacy or publicity rights of any third party.
        <br></br>11. Your Contents do not include sexual or pornographic material, defined by Webster’s Dictionary as „explicit descriptions or displays of sexual organs or activities intended to stimulate erotic rather than aesthetic or emotional feelings.
        <br></br>12. Your Contents do not contain any material that solicits personal information from anyone under the age of 18 or exploits people under the age of 18 in a sexual or violent manner.
        <br></br>13. Your Contents do not violate any federal or state law concerning pornography, or otherwise intended to protect the health or well-being of minors
        <br></br>14. Your Contents do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap.
        <br></br>15. Your Contents do not otherwise violate, or link to material that violates, any provision of these Terms of Use, or any applicable law or regulation.
        <br></br>
        There is no tolerance for objectionable content or abusive users in the App.

      </p>
      <h1 style={{ fontSize: 17, paddingBottom: 5,  }}>Termination</h1>
      <p style={{ fontSize: 15, }}>This EULA agreement is effective from the date you first use the Software and shall continue until terminated. You may terminate it at any time upon written notice to AllAmericanHighSchoolSport Inc.
        <br></br>
        It will also terminate immediately if you fail to comply with any term of this EULA agreement. Upon such termination, the licenses granted by this EULA agreement will immediately terminate and you agree to stop all access and use of the Software. The provisions that by their nature continue and survive will survive any termination of this EULA agreement.
      </p>
      <h1 style={{ fontSize: 17, paddingBottom: 5, paddingTop: 5 }}>Governing Law</h1>
      <p style={{ fontSize: 15, }}>This EULA agreement, and any dispute arising out of or in connection with this EULA agreement, shall be governed by and construed in accordance with the laws of us.
      </p>
      <p style={{ fontSize: 15, }}> <br></br>Oct 10, 2021
      </p>


    </div>
  );
}

export default App;
